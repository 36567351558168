import { axiosInstance } from '@/config/axios'

export const exportDocx = async (postIds, options) => {
  try {
    const { data } = await (await axiosInstance()).post(
      `export/posts/docx`,
      {
        post_ids: postIds,
        ...options
      },
      { responseType: 'blob' }
    )
    return data
  } catch (error) {
    console.debug(error)
  }
}

export const exportDocxFeed = async (feedId, feedSearchOptions, options) => {
  try {
  if (!feedSearchOptions) feedSearchOptions = {}
    let searchOptions = {...feedSearchOptions};
    searchOptions.limit = 2000
    searchOptions.offset = 0
    const { data } = await (await axiosInstance()).post(
      `export/feeds/${feedId}/docx`,
      {
        ...searchOptions,
        ...options
      },
      { responseType: 'blob' }
    )
    return data
  } catch (error) {
    console.debug(error)
  }
}
