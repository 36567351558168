export default (html) => {
	let preHtml = html;
	try {
		if (!/(href|src)="?'?(https?:)?\/\/.+/.test(html)) {
			preHtml = preHtml.replace(
				// eslint-disable-next-line no-useless-escape
				/(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{1,10}\b([-a-zA-Z0-9@:%_\+.~#?!&//=]*))/gi,
				'<a href=$1 rel="noreferrer noopener" target=blank>$1</a>'
			);
		}
	} catch (error) {
		console.debug(error);
	}
	return preHtml;
};
