<template>
	<div class="relative cursor-pointer">
		<div class="tooltip">
			<slot></slot>
		</div>
		<div
			class="absolute z-50 p-3 text-xs text-white bg-black rounded-md shadow-lg invisible tooltip-text"
		>
			<div v-html="text"></div>
			<slot name="text"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "Tooltip",
	props: {
		text: {
			type: String,
			default: "",
		},
		position: {
			type: String,
			default: "left",
		},
	},
	computed: {
		computedPosition () {
			return this.position.concat('-full')
		}
	}
};
</script>
<style scoped>
.tooltip-text {
	margin-left: -100px;
        margin-top: -70px;
	position: fixed;
}
.tooltip:hover + .tooltip-text, .tooltip-text:hover {
	visibility: visible;
}
</style>
